<template>
  <div class="container">
    <div class="header">
      <div class="flagship-type">
        <img v-if="flagshipType === 1" src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210903115717884.png" />
        <img v-else src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210903115743266.png" />
      </div>
    </div>
    <div class="rank-content">
      <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210903135049641.png" />
      <div class="flagship-list">
        <van-button class="active" type="primary" :to="'/PKIndex?source=result&token=' + token">榜单查询</van-button>
        <van-button type="primary">获奖记录</van-button>
      </div>
      <van-list v-model="loading" :finished="finished" finished-text="" @load="onLoad">
        <van-cell v-for="(item,index) in list" :key="index" :border="false">
          <div class="rank-result">
            <div class="content-left">
              <div class="user-level" v-if="list.length > 1">{{ item.levelId === 2 ?'金星':'银星'}}</div>
              <div class="user-head">
                <img :src="item.headerImg ? item.headerImg :'/img/100.b3582921.png'" />
              </div>
              <div class="user-info">
                <div class="ellipsis1">{{ item.agentName }}</div>
                <!-- <p class="ellipsis1">{{ item.agentCode }}</p> -->
              </div>
            </div>
            <div class="user-perf">订货业绩：<span>￥{{ item.performance }}</span></div>
          </div>
          <div class="gift-content" v-if="item.isWin">
            <p class="success-txt">恭喜您获得奖励!</p>
            <img :src="item.pirzeImg" />
            <van-row>
              <van-col class="ellipsis1" span="16">{{ item.prizeName }}</van-col>
              <van-col class="worth" span="8">价值：￥{{ item.prizePrice }}</van-col>
            </van-row>
            <p class="prompt" v-if="item.pirzeType === 2 ">系统将在5-7个工作日内自动存入您的采购券账户内</p>
            <!-- <div class="logistics">物流单号：{{ item.expressNum }}
              <van-button plain type="primary" round color="#999">查看物流</van-button>
            </div> -->
          </div>
          <div class="gift-empty" v-else>
            <img src="../../../assets/images/empty/content_01.png" />
            <p>很遗憾您本次暂未获得奖励！</p>
          </div>
        </van-cell>
      </van-list>
    </div>
  </div>
</template>

<script>
export default {
  name: "MatchResult",
  data() {
    return {
      loading: false,
      finished: false,
      list: [],
      token: '',
      flagshipType: 2
    }
  },
  created() {
    let { token } = this.$route.query;
    if (token) this.token = token;
  },
  methods: {
    onLoad() {
      this.get("/OnlyOnce/HeroCompetition/GetRewardList", {
      }, 2).then(res => {
        this.loading = false;
        this.finished = true;
        if (res.code === 1) {
          let list = res.response;
          let data = list.find(it => {
            return it.levelId === 2
          });
          if (data) this.flagshipType = 1;
          this.list = list;
        }
      }).catch(() => {
        this.loading = false;
        this.finished = true;
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 150px 15px 11px;
  min-height: calc(100% - 161px);
  background: url("http://freesaasofficedata.oss.aliyuncs.com/miShop/20210913110855394.png")
    no-repeat #c5e1ea;
  background-size: 100% auto;
  .header {
    padding-bottom: 11px;
    text-align: center;

    .flagship-type {
      text-align: right;

      img {
        width: 120px;
      }
    }
  }

  .rank-content {
    text-align: center;
    height: calc(100% - 60px);
    position: relative;
    background-color: #fff;
    > img {
      width: 104%;
      margin-left: -2%;
    }
    .flagship-list {
      position: absolute;
      top: 10px;
      left: 0;
      right: 0;
      z-index: 10;
      .van-button {
        height: 24px;
        border-color: #fff;
        border-radius: 32px;
        margin-right: 10%;
        background: linear-gradient(to bottom, #f4f4f4, #afafaf);
        background: -webkit-linear-gradient(to bottom, #f4f4f4, #afafaf);
        background: -moz-linear-gradient(to bottom, #f4f4f4, #afafaf);
        box-shadow: 0 3px 5px #c3c3c3;
        .van-button__content {
          .van-button__text {
            font-size: 12px;
          }
        }
        &.active {
          background: linear-gradient(to bottom, #d5f5ff, #44a8d1);
          background: -webkit-linear-gradient(to bottom, #d5f5ff, #44a8d1);
          background: -moz-linear-gradient(to bottom, #d5f5ff, #44a8d1);
          box-shadow: 0 3px 5px #75b0c9;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .van-list {
      background-color: #fff;
      .rank-result {
        display: flex;
        font-size: 12px;
        display: -webkit-flex;
        align-items: center;

        .content-left {
          width: 50%;
          display: flex;
          display: -webkit-flex;
          align-items: center;

          .user-level {
            color: #fff;
            padding: 0 6px;
            font-size: 10px;
            background: url("http://freesaasofficedata.oss.aliyuncs.com/miShop/20210903140622478.png")
              no-repeat;
            background-size: 100% 100%;
          }

          .user-head {
            padding: 0 6px;

            img {
              width: 22px;
              height: 22px;
              border-radius: 50%;
            }
          }

          .user-info {
            color: #000000;
            text-align: left;
            width: calc(100% - 34px);

            p {
              color: #999999;
              font-size: 9px;
            }
          }
        }

        .user-perf {
          width: 50%;
          color: #333;

          span {
            font-size: 14px;
            font-weight: bold;
          }
        }
      }
    }
  }

  .gift-empty {
    text-align: center;

    img {
      width: 150px;
    }

    p {
      color: #999;
      padding-top: 10px;
    }
  }

  .gift-content {
    color: #333333;
    font-size: 12px;

    .success-txt {
      color: #fd0000;
      line-height: 30px;
      text-align: center;
      letter-spacing: 1px;
    }
    > img {
      width: 100%;
      padding: 10px 0;
    }
    .van-row {
      .worth {
        color: #fd0000;
      }
    }
    .logistics {
      display: flex;
      align-content: center;
      justify-content: space-between;
      .van-button {
        height: 30px;
      }
    }
  }
}
</style>
